export const destructParams = (url: string) => {
  const search = url.replace("?", "").split("&");
  const objParams: Record<string, string> = {};
  search.forEach((item) => {
    const arrParam = item.split("=");
    objParams[arrParam[0]] = arrParam[1];
  });

  return objParams;
};
