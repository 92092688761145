import {
  IAnalysis,
  IAnalysisConfigurationData,
  IAutocompleteResponse,
  ICreateAnalysisRequest,
  ICreateAnalysisResponse,
  IDuplicateAnalysisResponse,
  IDuplicatePublicationRequest,
  IFollowing,
  ILastTitles,
  INewTitles,
  IPublishAnalysisRequest,
  IPublishedAnalysis
} from "types/analysis.model";
import { IVersionDataJson } from "types/version-data.model";
import { IChart, IDates } from "types/dashboard.model";
import { IReportRequest, IReportResponse } from "types/report.model";
import { authorizedRequest } from "./request";

/**
 * Retrieves a list of analyses based on the specified ownership criteria.
 *
 * @param {boolean} isOnlyMine - Whether to retrieve only analyses owned by the current user.
 * @return {Promise<IAnalysis[]>} A promise that resolves to an array of analysis objects.
 */
const getAnalysisList = (isOnlyMine: boolean): Promise<IAnalysis[]> =>
  authorizedRequest.get(`/api/analyzes/analysis/?mine=${isOnlyMine}`);

/**
 * Creates an analysis using the provided request body.
 *
 * @param {ICreateAnalysisRequest} body - The request body for creating the analysis.
 * @return {Promise<ICreateAnalysisResponse>} A promise that resolves to the response of the create analysis request.
 */
const createAnalysis = (
  body: ICreateAnalysisRequest
): Promise<ICreateAnalysisResponse> =>
  authorizedRequest.post("/api/analyzes/analysis/", { ...body });

/**
 * Updates the analysis with the specified ID.
 *
 * @param {number} id - The ID of the analysis to update.
 * @param {Object} body - The new data to update the analysis with.
 * @param {string} [body.name] - The new name of the analysis.
 * @param {number} [body.unit] - The new unit of the analysis.
 * @param {string} [body.currency] - The new currency of the analysis.
 * @param {number} [body.decimals_amount] - The new number of decimal places for the amount.
 * @return {Promise<ICreateAnalysisResponse>} A promise that resolves to the updated analysis.
 */
const updateAnalysis = (
  id: number,
  body: {
    name?: string;
    unit?: number;
    currency?: string;
    decimals_amount?: number;
  }
): Promise<ICreateAnalysisResponse> =>
  authorizedRequest.patch(`/api/analyzes/analysis/${id}/`, { ...body });

/**
 * Deletes an analysis by its ID.
 *
 * @param {number} id - The ID of the analysis to delete.
 * @return {Promise<void>} A Promise that resolves when the analysis is deleted successfully.
 */
const deleteAnalysis = (id: number): Promise<void> =>
  authorizedRequest.delete(`/api/analyzes/analysis/${id}/`);

/**
 * Retrieves the analysis detail for the given ID.
 *
 * @param {number} id - The ID of the analysis.
 * @return {Promise<ICreateAnalysisResponse>} A promise that resolves to the analysis detail.
 */
const getAnalysisDetail = (id: number): Promise<ICreateAnalysisResponse> =>
  authorizedRequest.get(`/api/analyzes/analysis/${id}/`);

/**
 * Retrieves the version data for a given version ID.
 *
 * @param {number} versionId - The ID of the version to retrieve data for.
 * @return {Promise<IVersionDataJson>} A Promise that resolves to the version data in JSON format.
 */
const getVersionData = (versionId: number): Promise<IVersionDataJson> =>
  authorizedRequest.get(`/api/analyzes/versions/${versionId}/`);

/**
 * Deletes a chart from the dashboard.
 *
 * @param {number} versionId - The ID of the dashboard version.
 * @param {number} chartId - The ID of the chart to be deleted.
 * @return {Promise} A promise that resolves when the chart is successfully deleted.
 */
const deleteChart = (versionId: number, chartId: number) =>
  authorizedRequest.delete(
    `/api/dashboard/version/${versionId}/charts/${chartId}/`
  );

/**
 * Updates the order number of a chart in the dashboard.
 *
 * @param {number} versionId - The ID of the dashboard version.
 * @param {number} chartId - The ID of the chart to be updated.
 * @param {number} orderNumber - The new order number of the chart.
 * @return {Promise} A promise that resolves when the chart is successfully updated.
 */
const updateChartOrder = (
  versionId: number,
  chartId: number,
  orderNumber: number
) =>
  authorizedRequest.patch(
    `/api/dashboard/version/${versionId}/charts/${chartId}/`,
    {
      order_number: orderNumber
    }
  );

const updateChart = (
  versionId: number,
  chartId: number,
  period: string,
  orderNumber: number
) =>
  authorizedRequest.patch(
    `/api/dashboard/version/${versionId}/charts/${chartId}/`,
    {
      type: period,
      order_number: orderNumber
    }
  );

/**
 * Creates a report for a specific version of an analysis.
 *
 * @param {number} versionId - The ID of the version to create the report for.
 * @param {any} body - The request body containing the necessary data to generate the report.
 * @return {Promise<IReport>} - A Promise that resolves to the generated report.
 */
const createReport = (versionId: number, body: { report: IReportRequest }) =>
  authorizedRequest.post(`/api/analyzes/versions/${versionId}/report/`, {
    ...body
  });

/**
 * Updates the report for a specific version of an analyze.
 *
 * @param {number} versionId - The ID of the version.
 * @param {any} body - The updated report data.
 * @return {Promise<IReportResponse>} A promise that resolves to the updated report.
 */
const updateReport = (
  versionId: number,
  body: { report: IReportRequest }
): Promise<IReportResponse> =>
  authorizedRequest.patch(`/api/analyzes/versions/${versionId}/report/`, {
    ...body
  });

/**
 * Deletes the report for a specific version of an analysis.
 *
 * @param {number} versionId - The ID of the version.
 * @return {Promise<void>} A promise that resolves with no value.
 */
const deleteReport = (versionId: number): Promise<void> =>
  authorizedRequest.delete(`/api/analyzes/versions/${versionId}/report/`);

/**
 * Retrieves new date titles for a specific version.
 *
 * @param {number} versionId - The ID of the version.
 * @param {ILastTitles} body - The body containing the last titles.
 * @return {Promise<INewTitles>} - A Promise that resolves to the new titles.
 */
const getNewDateTitles = (
  versionId: number,
  body: ILastTitles
): Promise<INewTitles> =>
  authorizedRequest.patch(
    `/api/analyzes/versions/${versionId}/new-date-titles/`,
    { ...body }
  );

/**
 * Retrieves the stock symbols that match the given search phrase.
 *
 * @param {string} str - The search phrase to match.
 * @return {Promise<IAutocompleteResponse[]>} - A promise that resolves to an array of autocomplete responses.
 */
const getStockSymbol = (str: string): Promise<IAutocompleteResponse[]> =>
  authorizedRequest.get(
    `api/analyzes/ticker-autocomplete/?search_phrase=${str}`
  );

/**
 * Deletes a version of an analyze based on its ID.
 *
 * @param {number} id - The ID of the version to be deleted.
 * @return {Promise<void>} A Promise that resolves when the version is deleted successfully.
 */
const deleteVersion = (id: number): Promise<void> =>
  authorizedRequest.delete(`/api/analyzes/versions/${id}`);

/**
 * Retrieves the analysis configuration data for a specific stock symbol and data source.
 *
 * @param {string} stockSymbol - The stock symbol for which to retrieve the analysis configuration data.
 * @param {string} dataSource - The data source from which to retrieve the analysis configuration data.
 * @return {Promise<IAnalysisConfigurationData>} A promise that resolves to the analysis configuration data.
 */
const getAnalysisConfigurationData = (
  stockSymbol: string,
  dataSource: string
): Promise<IAnalysisConfigurationData> =>
  authorizedRequest.get(
    `api/analyzes/analysis-configuration-data/?ticker=${stockSymbol}&data_source=${dataSource}`
  );

/**
 * Retrieves the duplicate analysis for the specified ID.
 *
 * @param {number} id - The ID of the analysis.
 * @return {Promise<IDuplicateAnalysisResponse>} A promise that resolves with the duplicate analysis response.
 */
const duplicateAnalysis = (id: number): Promise<IDuplicateAnalysisResponse> =>
  authorizedRequest.get(`/api/analyzes/analysis/${id}/duplicate`);

/**
 * Retrieves the translation of company data by making a POST request to the "/api/analyzes/company-data-translation/" endpoint.
 *
 * @param {number} companyId - The ID of the company for which the data translation is requested.
 * @return {Promise<{ description: string }>} A promise that resolves to an object containing the description of the translated data.
 */
const getTranslation = (companyId: number): Promise<{ description: string }> =>
  authorizedRequest.post("/api/analyzes/company-data-translation/", {
    company_id: companyId
  });

/**
 * Publishes an analysis.
 *
 * @param {IPublishAnalysisRequest} body - The request body containing the analysis details.
 * @return {Promise} A promise that resolves with the response of the publication request.
 */
const publishAnalysis = (body: IPublishAnalysisRequest): Promise<void> =>
  authorizedRequest.post("/api/analyzes/publications/", {
    ...body
  });

/**
 * Retrieves a list of published analyses for a given stock symbol.
 *
 * @param {string} stock_symbol - The stock symbol for which to retrieve the list of published analyses.
 * @return {Promise<IPublishedAnalysis[]>} A promise that resolves to an array of published analysis objects.
 */
const gePublishedAnalysisList = (params?: {
  search?: string;
  created_by?: number;
  language?: string;
  publication_date?: string;
}): Promise<IPublishedAnalysis[]> =>
  authorizedRequest.get(`/api/analyzes/publications/`, {
    params: { ...params }
  });

/**
 * Retrieves a list of published analyses for a given stock symbol.
 *
 * @param {string} stock_symbol - The stock symbol for which to retrieve the list of published analyses.
 * @return {Promise<IPublishedAnalysis[]>} A promise that resolves to an array of published analysis objects.
 */
const getOwnPublishedAnalysisList = (params?: {
  search?: string;
  language?: string;
}): Promise<{
  publications: IPublishedAnalysis[];
  followings: IFollowing[];
}> =>
  authorizedRequest.get(`/api/analyzes/publications/mine`, {
    params: { ...params }
  });

/**
 * Unsubscribes the specified author.
 *
 * @param {number} authorId - The ID of the author to unsubscribe from.
 * @return {Promise<void>} A promise that resolves with the result of the unsubscription.
 */
const unSubscribeAuthor = (authorId: number): Promise<void> =>
  authorizedRequest.post(
    `/api/notifications/subscriptions/${authorId}/subscribe-author/`,
    {
      is_following: false
    }
  );

/**
 * Duplicates a published analysis with the given ID.
 *
 * @param {number} id - The ID of the published analysis to duplicate.
 * @param {IDuplicatePublicationRequest} data - The data of the analysis to use for duplication.
 * @return {Promise<Omit<IAnalysis, "created_at">>} - A promise that resolves with the duplicated analysis.
 */
const duplicatePublishedAnalysis = (
  id: number,
  data: IDuplicatePublicationRequest
): Promise<Omit<IAnalysis, "created_at">> =>
  authorizedRequest.post(`/api/analyzes/publications/${id}/duplicate/`, {
    ...data
  });

/**
 * Updates a publication with the given ID.
 *
 * @param {number} id - The ID of the publication to update.
 * @param {IPublishedAnalysis} data - The data to update the publication with.
 * @return {Promise<void>} A promise that resolves with no value.
 */
const updatePublication = (id: number, data: IPublishedAnalysis) =>
  authorizedRequest.put(`/api/analyzes/publications/${id}/`, { ...data });

/**
 * Publishes a publication with the specified ID.
 *
 * @param {number} id - The ID of the publication to be published.
 * @param {{ is_public: boolean }} data - The data object containing the is_public property indicating whether the publication should be made public.
 * @return {Promise<void>} A promise that resolves when the publication is successfully published.
 */
const publishPublication = (
  id: number,
  data: { is_public: boolean }
): Promise<void> =>
  authorizedRequest.post(`/api/analyzes/publications/${id}/publish/ `, {
    ...data
  });

const updatePublicationDescription = (
  id: number,
  data: { description: string }
): Promise<void> =>
  authorizedRequest.patch(`/api/analyzes/publications/${id}/ `, {
    ...data
  });

/**
 * Updates the name of a specific version in the analyzes API.
 *
 * @param {number} versionId - The ID of the version to be renamed.
 * @param {object} data - The data containing the new name.
 * @param {string} data.name - The new name for the version.
 * @return {Promise<void>} A promise that resolves when the version is renamed.
 */
const renameVersion = (
  versionId: number,
  data: { name: string }
): Promise<void> =>
  authorizedRequest.patch(`/api/analyzes/versions/${versionId}`, {
    ...data
  });

const getCompanyProfileContent = (
  analysisId: number,
  companyName: string
): Promise<{ company_profile: string }> =>
  authorizedRequest.get(`/api/analyzes/analysis/${analysisId}/company-profile/?company_name=${companyName}/
`);

const getCompanySwotContent = (
  analysisId: number,
  companyName: string
): Promise<{
  company_swot: string;
}> =>
  authorizedRequest.get(`/api/analyzes/analysis/${analysisId}/company-swot/?company_name=${companyName}/
`);

const getSupplyChainContent = (
  analysisId: number,
  companyName: string
): Promise<{
  supply_chain: string;
}> =>
  authorizedRequest.get(`/api/analyzes/analysis/${analysisId}/supply-chain/?company_name=${companyName}/
`);

const getProfitabilityIndustryContent = (
  id: number
): Promise<{
  profitability_vs_industry: string;
}> =>
  authorizedRequest.get(`/api/analyzes/versions/${id}/profitability-vs-industry/
`);

const getFinancingOptionsContent = (
  id: number
): Promise<{
  financing_options: string;
}> =>
  authorizedRequest.get(`/api/analyzes/versions/${id}/financing-options/
`);

const getCapitalImprovementsContent = (
  id: number
): Promise<{
  capital_improvements: string;
}> =>
  authorizedRequest.get(`/api/analyzes/versions/${id}/capital-improvements/
`);

const getWorksheetReportContent = (
  versionId: number,
  worksheetId: number
): Promise<{ report_info: string }> =>
  authorizedRequest.get(`/api/analyzes/versions/${versionId}/worksheet-report/?worksheet=${worksheetId}
`);

const createChart = (
  versionId: number,
  body: {
    chart_id: number;
    report: number | null;
    is_report?: boolean;
    order_number?: number;
    default?: boolean;
    type: string;
  }[]
): Promise<{
  chart: IChart;
  dates: IDates;
  report: IReportResponse | null;
}> => {
  if (body.every((item) => item.default)) {
    return authorizedRequest.post(
      `/api/dashboard/version/${versionId}/charts/?default=true`,
      {
        charts: body
      }
    );
  }
  return authorizedRequest.post(`/api/dashboard/version/${versionId}/charts/`, {
    charts: body
  });
};

const createChartForReport = (
  versionId: number,
  body: {
    chart_id: number;
    report: number;
    order_number?: number;
    type: string;
  }[]
): Promise<{
  charts: { chart: IChart; dates: IDates; report: IReportResponse | null }[];
}> =>
  authorizedRequest.post(
    `/api/dashboard/version/${versionId}/charts/?is_report=true/`,
    {
      charts: body
    }
  );

const analysisServices = {
  getAnalysisList,
  createAnalysis,
  updateAnalysis,
  deleteAnalysis,
  getAnalysisDetail,
  getVersionData,
  deleteChart,
  createReport,
  updateReport,
  deleteReport,
  getNewDateTitles,
  getStockSymbol,
  deleteVersion,
  getAnalysisConfigurationData,
  duplicateAnalysis,
  getTranslation,
  publishAnalysis,
  gePublishedAnalysisList,
  duplicatePublishedAnalysis,
  getOwnPublishedAnalysisList,
  updatePublication,
  publishPublication,
  unSubscribeAuthor,
  renameVersion,
  getCompanyProfileContent,
  getCompanySwotContent,
  getSupplyChainContent,
  getProfitabilityIndustryContent,
  getFinancingOptionsContent,
  getCapitalImprovementsContent,
  getWorksheetReportContent,
  createChart,
  createChartForReport,
  updatePublicationDescription,
  updateChartOrder,
  updateChart
};

export default analysisServices;
