import { createAsyncThunk } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";
import {
  IComparableCompanyData,
  ICreateAnalysisResponse,
  IIndustryCountry,
  IIndustryWithSwot
} from "types/analysis.model";
import {
  IVersionDataJson,
  IMovePeriodRequest,
  ICalculateResponse
} from "types/version-data.model";
import { IChart, IDashboard, IDates } from "types/dashboard.model";
import { METADATA_JSON } from "constants/config";
import { IReportResponse } from "types/report.model";
import { analysisServices } from "services";
import { IAvailableChart } from "./analysis.types";

export const getCustomerAnalysisData = createAsyncThunk(
  "analysis/getCustomerAnalysisData",
  async (
    state: { analysisId: number; currentVersion?: number },
    { rejectWithValue }
  ) => {
    const { analysisId, currentVersion } = state;
    try {
      const analysisResponse = (await authorizedRequest.get(
        `/api/analyzes/analysis/${analysisId}/`
      )) as ICreateAnalysisResponse;

      if (analysisResponse) {
        const versionId = currentVersion || analysisResponse.versions[0]?.id;
        const versionData = (await authorizedRequest.get(
          `/api/analyzes/versions/${versionId}/`
        )) as IVersionDataJson;
        const templateData = await authorizedRequest.get(
          `/api/analyzes/templates/${analysisResponse.template}/`
        );

        const availableCharts = (await authorizedRequest.get(
          `/api/dashboard/version/${versionId}/available-charts/`
        )) as IAvailableChart[];

        const dashboardData = (await authorizedRequest.get(
          `/api/analyzes/versions/${versionId}/dashboard/`
        )) as IDashboard;

        const report = (await authorizedRequest.get(
          `/api/analyzes/versions/${versionId}/report/`
        )) as any;

        const calculatedVersion = (await authorizedRequest.patch(
          `/api/analyzes/versions/${versionId}/calculate/`,
          {
            version_meta_data: versionData.version_meta_data,
            calculated_version: versionData.calculated_version,
            save_as_new: false
          }
        )) as ICalculateResponse;

        sessionStorage.setItem(METADATA_JSON, JSON.stringify(analysisResponse));

        return {
          metadata: analysisResponse,
          versionData: {
            ...versionData,
            calculated_version: calculatedVersion.calculated_version
          },
          templateData,
          availableCharts,
          dashboardData,
          report
        };
      }
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getVersionData = createAsyncThunk(
  "analysis/getVersionData",
  async (state: { versionId: number }, { rejectWithValue }) => {
    const { versionId } = state;
    try {
      const versionData = (await authorizedRequest.get(
        `/api/analyzes/versions/${versionId}/`
      )) as IVersionDataJson;

      return versionData;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getAvailableCharts = createAsyncThunk(
  "analysis/getAvailableCharts",
  async (state: { versionId: number }, { rejectWithValue }) => {
    const { versionId } = state;
    try {
      const availableCharts = (await authorizedRequest.get(
        `/api/dashboard/version/${versionId}/available-charts/`
      )) as IAvailableChart[];

      return availableCharts;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const moveProjection = createAsyncThunk(
  "analysis/moveProjection",
  async (
    state: {
      versionId: number;
      body: IMovePeriodRequest;
      positionIndex: string;
      dateTitle: string;
    },
    { rejectWithValue }
  ) => {
    const { versionId, body, positionIndex, dateTitle } = state;
    try {
      const response = await authorizedRequest.put(
        `/api/analyzes/versions/${versionId}/move-period/`,
        { ...body }
      );

      return { response, positionIndex, dateTitle };
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getCustomerDashboardData = createAsyncThunk(
  "analysis/getCustomerDashboardData",
  async (state: { versionId: number }, { rejectWithValue }) => {
    const { versionId } = state;
    try {
      const response = (await authorizedRequest.get(
        `/api/analyzes/versions/${versionId}/dashboard/`
      )) as IDashboard;
      const isOldOrderNumber = response.dashboard_charts.every(
        (item) => item.version_chart_order_number === 1
      );

      if (isOldOrderNumber) {
        response.dashboard_charts.forEach(async (item, index) => {
          await analysisServices.updateChartOrder(
            versionId,
            item.chart_per_version_id,
            index + 1
          );
        });
      }

      return {
        ...response,
        dashboard_charts: [...response.dashboard_charts].sort(
          (a, b) => a.version_chart_order_number - b.version_chart_order_number
        )
      };
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const createChart = createAsyncThunk(
  "analysis/createChart",
  async (
    state: {
      versionId: number;
      body: {
        chart_id: number;
        report: boolean | null;
        order_number?: number;
      }[];
    },
    { rejectWithValue }
  ) => {
    const { versionId, body } = state;
    try {
      const response = (await authorizedRequest.post(
        `/api/dashboard/version/${versionId}/charts/`,
        {
          charts: body
        }
      )) as {
        charts: {
          chart: IChart;
          dates: IDates;
          report: IReportResponse | null;
        }[];
      };

      return response.charts;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getReport = createAsyncThunk(
  "analysis/getReport",
  async (state: { versionId: number }, { rejectWithValue }) => {
    const { versionId } = state;
    try {
      const response = (await authorizedRequest.get(
        `/api/analyzes/versions/${versionId}/report/`
      )) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const deleteChart = createAsyncThunk(
  "analysis/deleteChart",
  async (
    state: { versionId: number; chartId: number },
    { rejectWithValue }
  ) => {
    const { versionId, chartId } = state;
    try {
      (await authorizedRequest.delete(
        `/api/dashboard/version/${versionId}/charts/${chartId}/`
      )) as any;
      return chartId;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getStockSymbols = createAsyncThunk(
  "analysis/getStockSymbol",
  async (state: { searchPhrase: string }, { rejectWithValue }) => {
    const { searchPhrase } = state;
    try {
      const response = (await authorizedRequest.get(
        `api/analyzes/ticker-autocomplete/?search_phrase=${searchPhrase}`
      )) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const duplicateAnalysis = createAsyncThunk(
  "analysis/duplicateAnalysis",
  async (state: { id: number }, { rejectWithValue }) => {
    const { id } = state;
    try {
      const response = authorizedRequest.get(
        `/api/analyzes/analysis/${id}/duplicate/`
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getIndustryCompanyAnalysis = createAsyncThunk(
  "analysis/getIndustryCompanyAnalysis",
  async (
    state: { analysisId: number; countries?: string[]; industry?: string },
    { rejectWithValue }
  ) => {
    const { analysisId, countries, industry } = state;
    try {
      const response = authorizedRequest.post(
        `/api/analyzes/analysis/${analysisId}/industry-analysis/`,
        {
          countries,
          industry
        }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getComparableData = createAsyncThunk(
  "analysis/getComparableData",
  async (state: { symbols: string; analysis: number }, { rejectWithValue }) => {
    const { symbols, analysis } = state;
    try {
      const res = (await authorizedRequest.post(
        `/api/analyzes/comparable-company-data/`,
        {
          symbols,
          analysis
        }
      )) as IComparableCompanyData[];
      return res;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getExistingComparableData = createAsyncThunk(
  "analysis/getComparableData",
  async (state: { analysisId: number }, { rejectWithValue }) => {
    const { analysisId } = state;
    try {
      const res = (await authorizedRequest.get(
        `/api/analyzes/comparable-company-data/`,
        {
          params: {
            analysis: analysisId
          }
        }
      )) as IComparableCompanyData[];
      return res;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getIndustryCountries = createAsyncThunk(
  "analysis/getIndustryCountries",
  async (state, { rejectWithValue }) => {
    try {
      const response = (await authorizedRequest.get(
        `/api/analyzes/industry-countries/`
      )) as IIndustryCountry[];
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getSwot = createAsyncThunk(
  "analysis/getSwot",
  async (state: { industryId: number }, { rejectWithValue }) => {
    const { industryId } = state;
    try {
      const response = (await authorizedRequest.get(
        `api/analyzes/industries/${industryId}/swot/`
      )) as IIndustryWithSwot;
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getSwotTranslated = createAsyncThunk(
  "analysis/getSwotTranslated",
  async (state: { industryId: number }, { rejectWithValue }) => {
    const { industryId } = state;
    try {
      await authorizedRequest.get(
        `api/analyzes/industries/${industryId}/swot/`
      );
      const response = (await authorizedRequest.get(
        `api/analyzes/industries/${industryId}/swot-translated/`
      )) as IIndustryWithSwot;
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getDataSourceRatio = createAsyncThunk(
  "analysis/getDataSourceRatio",
  async (state: { symbol: string }, { rejectWithValue }) => {
    const { symbol } = state;
    try {
      const response = await authorizedRequest.get(`api/data-sources/ratio/`, {
        params: {
          symbol
        }
      });
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getPriceTarget = createAsyncThunk(
  "analysis/getPriceTarget",
  async (state: { symbol: string }, { rejectWithValue }) => {
    const { symbol } = state;
    try {
      const response = await authorizedRequest.get(
        `api/data-sources/price-target/`,
        {
          params: {
            symbol: symbol.replaceAll(".US", "")
          }
        }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getLiveStockPrice = createAsyncThunk(
  "analysis/getLiveStockPrice",
  async (state: { symbol: string }, { rejectWithValue }) => {
    const { symbol } = state;
    try {
      const response = await authorizedRequest.get(
        `api/data-sources/stock-price/`,
        {
          params: {
            symbol: symbol.replaceAll(".US", "")
          }
        }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getKeyMetrics = createAsyncThunk(
  "analysis/getKeyMetrics",
  async (state: { symbol: string }, { rejectWithValue }) => {
    const { symbol } = state;
    try {
      const response = await authorizedRequest.get(
        `api/data-sources/key-metrics/`,
        {
          params: {
            symbol
          }
        }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getPiotroskiScore = createAsyncThunk(
  "analysis/getPiotroskiScore",
  async (state: { symbol: string }, { rejectWithValue }) => {
    const { symbol } = state;
    try {
      const response = await authorizedRequest.get(
        `api/data-sources/piotroski-f-score/`,
        {
          params: {
            symbol
          }
        }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);
